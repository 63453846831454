import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AdminRoutingModule } from './admin-routing.module';
import { SharedModule } from '../shared/shared.module';
import { AdminComponent } from './admin.component';
import { LayoutModule } from './layout/layout.module';


@NgModule({
  declarations: [
    AdminComponent,
  ],
  imports: [
     AdminRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    LayoutModule
  ],
})
export class AdminModule { }
