import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  codVista: number;
  codVistaPadre: number;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

/* const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'collapse',
        icon: 'feather icon-home',
        children: [
          {
            id: 'analytics',
            title: 'Analytics',
            type: 'item',
            url: '/dashboard/analytics',
            breadcrumbs: false
          },
          {
            id: 'sale',
            title: 'Sales',
            type: 'item',
            url: '/dashboard/sale'
          }
        ]
      },
      {
        id: 'configuracion',
        title: 'Configuracion',
        type: 'collapse',
        icon: 'feather icon-settings',
        children: [
          {
            id: 'permisos',
            title: 'Permisos',
            type: 'item',
            url: '/configuracion/permisos'
          },
          {
            id: 'roles',
            title: 'Roles',
            type: 'item',
            url: '/configuracion/roles'
          }
        ]
      }
    ]
  }
]; */

@Injectable()
export class NavigationItem {
 /*  public get() {
    return NavigationItems;
  } */
}
