<div class="container" *ngIf="this.gradientConfig.layout === 'horizontal' && this.gradientConfig.subLayout === 'horizontal-2'; else mainHeader">
    <ng-container *ngTemplateOutlet="mainHeader"></ng-container>
</div>
<ng-template #mainHeader>
    <div class="m-header">
        <a class="mobile-menu" id="mobile-collapse" (click)="navCollapse()"><span></span></a>
        <a [routerLink]="['/dashboard/analytics']" class="b-brand">
            <img [routerLink]="['/dashboard']" id="main-logo" src="/assets/images/mbusiness.png" alt="" class="logo" style="  max-height: 150px;
      height: auto;
      max-width: 150px;
      width: auto;
      position: relative;">
            <img src="/assets/images/mbusiness.png" alt="" class="logo-thumb">
        </a>
        <a class="mob-toggler" [ngClass]="{'on' : this.menuClass}" href="javascript:" (click)="toggleMobOption()"><i class="feather icon-more-vertical"></i></a>
    </div>
    <div class="collapse navbar-collapse" [style.display]="this.collapseStyle">
        <app-nav-left class="mr-auto"></app-nav-left>
        <app-nav-right class="ml-auto"></app-nav-right>
    </div>
</ng-template>