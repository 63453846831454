import Swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { _throw as throwError } from 'rxjs/observable/throw';
import { map, catchError } from 'rxjs/operators';
import { BASE_URL } from 'src/app/apiconfig/apiconfig';
import { Vista } from 'src/app/models/vista.model';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
//import { ToastyService } from 'ng2-toasty';

@Injectable({
  providedIn: 'root'
})
export class VistasService {
  private httpHeaders = new HttpHeaders({ 'Content-type': 'application/json' });

  headers: HttpHeaders;

  constructor(
    public http: HttpClient,
    public router: Router,
    //private toastyService: ToastyService

  ) { }

  get(): Observable<Vista[]> {
    const url = `${BASE_URL}vistas`;
    console.log(url);
    return this.http.get(url).pipe(
      map((response: Vista[]) => response));
  }



  search(codvista: number, tipo: string, nivel: number, clickable: boolean, codvistapadre: number, secuencia: number) {
    let url = `${BASE_URL}vistas/search`;
    console.log(url);
    return this.http.get(url).pipe(
      map((response: Vista[]) => response));
  }
  
  getById(codvista) {
    const url = `${BASE_URL}vistas/${codvista}`;
    console.log(url);
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  create(vista: Vista): Observable<Vista> {
    return this.http
      .post<any>(`${BASE_URL}vistas`, vista).pipe(
        catchError(e => {
          this.error(
            'Error al crear'
          );
          console.error('ERROR agregar crear', e.error);
          if (e.error.header) {
            Swal.fire(e.error.header, e.error.message, 'error');
          }
          return throwError(e);
        })
      );
  }

  update(vista: Vista): Observable<Vista> {
    return this.http.put<any>(`${BASE_URL}vistas`, vista).pipe(
      catchError(e => {
        this.error(
          'Error al actualizar'
        );
        console.error('ERROR actualizar', e.error);
        if (e.error.header) {
          Swal.fire(e.error.header, e.error.message, 'error');
        }
        return throwError(e);
      })
    );
  }

  updateAll(vistas: Vista[]): Observable<Vista[]> {
    return this.http.put<any>(`${BASE_URL}vistas/all`, vistas).pipe(
      catchError(e => {
        this.error(
          'Error al actualizar'
        );
        console.error('ERROR actualizar', e.error);
        if (e.error.header) {
          Swal.fire(e.error.header, e.error.message, 'error');
        }
        return throwError(e);
      })
    );
  }

  delete(id: number): Observable<Vista> {
    return this.http.delete<Vista>(`${BASE_URL}vistas${`/${id}`}`).pipe(
      catchError(e => {
        this.error(
          'Error al eliminar'
        );
        console.error('ERROR', e.error);
        if (e.error.header) {
          Swal.fire(e.error.header, e.error.message, 'error');
        }
        return throwError(e);
      })
    );
  }

  error(m) {
    // this.toastyService.error({
    //   title: 'Error',
    //   msg: m,
    //   showClose: false,
    //   timeout: 3000,
    //   theme: 'bootstrap'
    // });
  }

}
