import { Authorities } from './authorities.model';

export class Login {
  //  ? significa opcional
  constructor(
    public username: string,
    public password: string,
  ) { }
}

