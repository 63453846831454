import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  // aplicar guard en cualquier ruta
  providedIn: 'root'
})
export class AuthTokenGuard implements CanActivate {
  constructor(
    public authService: AuthService,
    public router: Router
  ) { }

  // de prueba
  canActivate(): Promise<boolean> | boolean {
    console.log('AuthToken Guard');
    let token = this.authService.token;
    if (!token) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }

  /*canActivate(): Promise<boolean> | boolean {
     console.log('AuthToken Guard');
     let token = this.authService.token;
     let payload = JSON.parse(atob(token.split('.')[1]));
     let ex = new Date(payload.exp * 1000);
     console.log('token exp', ex);
     let expirado = this.expired(payload.exp);
     if (expirado) {
       this.router.navigate(['/auth/signin']);
       return false;
     }
     return this.checkRenewToken(payload.exp);
   }
 
   checkRenewToken(fechaExp: number): Promise<boolean> {
     return new Promise((resolve, reject) => {
       let tokenExp = new Date(fechaExp * 1000);
       let now = new Date();
       // ahora.setTime(ahora.getTime() + 10 * 60 * 60 * 1000);
       now.setTime(now.getTime() + 7 * 60 * 1000);
       let temp = new Date();
       console.log('limit', tokenExp);
       console.log(' ahora', now);
       if (tokenExp.getTime() > now.getTime()) {
         resolve(true);
       } else {
         this.authService.renewToken().subscribe(() => {
           resolve(true);
         }, () => {
           this.router.navigate(['/auth/signin']);
           reject(false);
         }
         );
       }
     });
   }
 
   expired(exp: number) {
     let now = new Date().getTime() / 1000;
     if (exp < now) {
       return true;
     } else {
       return false;
     }
 
   }*/
}
