<div class="auth-wrapper">
    <div class="auth-content">
        <div class="card">
            <div class="row align-items-center text-center">
                <div class="col-md-12">
                    <div class="card-body">
                        <form ngNativeValidate #f="ngForm" class="form-horizontal form-material" (ngSubmit)="ingresar( f )">
                            <a href="javascript:void(0)" class="text-center db">
                                <img src="/assets/images/logo-mb.png" alt="" class="img-fluid mb-4" style="width:60%;height:auto; ">
                            </a>
                            <!--    <h4 class="mb-3 f-w-400">Signin</h4> -->
                            <div class="input-group mb-4">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="feather icon-mail"></i></span>
                                </div>
                                <input [ngModel]="username" id="usuario" name="username" class="form-control" type="email" required placeholder="Correo del usuario">
                            </div>
                            <div class="input-group mb-4">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="feather icon-lock"></i></span>
                                </div>
                                <input ngModel name="password" class="form-control" type="password" required placeholder="Contraseña">
                            </div>
                            <div class="form-group text-left mt-2">
                                <div class="checkbox checkbox-primary d-inline">
                                    <input [(ngModel)]="recuerdame" name="recuerdame" id="checkbox-signup" type="checkbox" class="filled-in chk-col-light-blue">
                                    <label for="checkbox-fill-a1" class="cr"> Recuérdame</label>
                                </div>
                            </div>
                            <button class="btn btn-block btn-primary mb-4" type="submit">Ingresar</button>
                            <!--   <p class="mb-2 text-muted">Forgot password? <a [routerLink]="['/auth/reset-password']" class="f-w-400">Reset</a></p>
                        <p class="mb-0 text-muted">Don’t have an account? <a [routerLink]="['/auth/signup']" class="f-w-400">Signup</a></p> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>