import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminRoutingModule } from './admin/admin-routing.module';
import { MaintenErrorComponent } from './auth/mainten-error/mainten-error.component';
import { AuthRoutingModule } from './auth/auth-routing.module';
import { LoginComponent } from './login/login.component';



const routes: Routes = [
  { path: 'login', component: LoginComponent },
 // { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: '**', component: MaintenErrorComponent },
];



@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    AdminRoutingModule,
    AuthRoutingModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }



/* const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./admin/pages/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path: 'configuracion',
        loadChildren: () => import('./admin/pages/configuracion/configuracion.module').then(module => module.ConfiguracionModule)
      },
      {
        path: 'parametros',
        loadChildren: () => import('./admin/pages/parametros/parametros.module').then(module => module.ParametrosModule)
      },

    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./auth/pages/authentication/authentication.module').then(module => module.AuthenticationModule)
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
 */