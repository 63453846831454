import { Injectable } from '@angular/core';
import { VISTAS } from 'src/app/fack-db/vistas-data';
import { Vista } from 'src/app/models/vista.model';
import { VistasService } from '../vistas/vistas.service';
import { NavigationItem } from 'src/app/admin/layout/navigation/navigation';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  vistas: Vista[];

  navigation: NavigationItem[] = [];
  // navigationSub: Navigation[] = [];
  menus: NavigationItem[] = [];
  items: NavigationItem[] = [];

  constructor(private vistaService: VistasService) { }

  loadVistas() {
    this.vistaService.get()
      .subscribe((response: any) => {
        this.vistas = response as Vista[];
        this.vistas.sort((a, b) => (a.secuencia - b.secuencia));
        console.log(`vistas`, this.vistas);
        this.dynamicSidebar();
      });
    //this.vistas = VISTAS;
  }

  sortBySecuencia() {
    this.vistas.sort((a, b) => (a.secuencia - b.secuencia));
  }
  public get() {
    return this.navigation;
  }

  dynamicSidebar() {
    this.addMenus();
    this.addItems();
    this.addSubItems();
    this.addHead();
    // let items = await this.addItems().then();
    // let subItems = await this.addSubItems().then();
    console.log(this.navigation);
    return this.navigation;
  }

  addHead() {
    const item: NavigationItem = new NavigationItem();
    item.id = 'navigation',
      item.title = 'Navigation',
      item.type = 'group',
      item.icon = 'feather icon-monitor',
      item.children = this.menus;
    this.navigation.push(item);
  }

  addMenus() {
    for (const i of this.vistas) {
      if (i.nivel === 0) {
        const item: NavigationItem = new NavigationItem();
        item.id = i.nombreVista;
        item.title = i.titulo;
        if (i.clickable === true) {
          item.type = 'item';
        } else {
          item.type = 'collapse';
        }
        item.hidden = false;
        item.url = i.url;
        item.icon = i.icono;
        item.children = [];
        // item.breadcrumbs = false;
        item.codVista = i.codVista;
        item.codVistaPadre = i.codVistaPadre;
        this.menus.push(item);
      }
    }
  }

  addItems() {
    this.vistas.sort((a, b) => (a.secuencia - b.secuencia));
    for (const i of this.vistas) {
      if (i.nivel === 1) {
        const item: NavigationItem = new NavigationItem();
        item.id = i.nombreVista;
        item.title = i.titulo;
        if (i.clickable === true) {
          item.type = 'item';
        } else {
          item.type = 'collapse';
        }
        item.hidden = false;
        item.url = i.url;
        item.children = [];
        item.breadcrumbs = true;
        item.codVista = i.codVista;
        item.codVistaPadre = i.codVistaPadre;
        const index = this.menus.findIndex(x => x.codVista === item.codVistaPadre);
        if (index > -1) {
          this.menus[index].children.push(item);
        }
      }
    }
  }

  async addSubItems() {
    this.vistas.sort((a, b) => (a.secuencia - b.secuencia));
    for (const i of this.vistas) {
      if (i.nivel === 2) {
        const item: NavigationItem = new NavigationItem();
        item.title = i.titulo;
        item.url = i.url;
        item.type = 'item';
        item.breadcrumbs = true;
        item.codVista = i.codVista;
        item.codVistaPadre = i.codVistaPadre;
        item.hidden = false;
        // item.external = true;
        let indexes: number[] = await this.getIndexes(item.codVistaPadre);
        let indexMenu = indexes[0];
        let indexItem = indexes[1];
        // if (indexMenu > -1 && indexItem > -1) {
        this.menus[indexMenu].children[indexItem].children.push(item);
        // }
      }
    }
  }

  async getIndexes(codPadreSubItem) {
    let indexes: number[];
    for await (const menu of this.menus) {
      if (menu.children.length > 0) {
        let indexMenu = 0;
        indexMenu = this.menus.findIndex(x => x.codVista === menu.codVista);
        for await (const hijo of menu.children) {
          const indexItem = menu.children.findIndex(x => x.codVista === codPadreSubItem);
          if (indexItem > -1) {
            console.log('indexMenu: ' + indexMenu + ' - indexItem: ' + indexItem);
            indexes = [indexMenu, indexItem];
            return indexes;
          }
        }

      }
    }
  }

}

