import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { AuthGuard } from '../services/auth/auth-guard.service';
import { AuthTokenGuard } from '../services/service.index';


const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/dashboard/analytics',
        canActivate: [AuthTokenGuard],
        pathMatch: 'full'
    },
      {
        path: 'dashboard',
        canActivate: [AuthTokenGuard],
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path: 'configuracion',
        canActivate: [AuthTokenGuard],
        loadChildren: () => import('./pages/configuracion/configuracion.module').then(module => module.ConfiguracionModule)
      },
      {
        path: 'parametros',
        canActivate: [AuthTokenGuard],
        loadChildren: () => import('./pages/parametros/parametros.module').then(module => module.ParametrosModule)
      },

    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
