import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SharedModule } from 'src/app/shared/shared.module';
import { NavigationComponent } from './navigation/navigation.component';
import { NavContentComponent } from './navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NavLeftComponent } from './nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './nav-bar/nav-right/nav-right.component';
import { ChatUserListComponent } from './nav-bar/nav-right/chat-user-list/chat-user-list.component';
import { FriendComponent } from './nav-bar/nav-right/chat-user-list/friend/friend.component';
import { ChatMsgComponent } from './nav-bar/nav-right/chat-msg/chat-msg.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { ToggleFullScreenDirective } from 'src/app/shared/full-screen/toggle-full-screen';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationItem } from './navigation/navigation';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { SpinnerComponent } from './spinner/spinner.component';

import { NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ChatUserListComponent,
    FriendComponent,
    ChatMsgComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    BreadcrumbComponent,
    SpinnerComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgbButtonsModule,
    NgbDropdownModule,
    NgbTabsetModule,
    NgbTooltipModule



  ],
  exports: [
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ChatUserListComponent,
    FriendComponent,
    ChatMsgComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    BreadcrumbComponent,
    SpinnerComponent],

  providers: [
    NavigationItem
  ]
})
export class LayoutModule { }
