import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// //import { ToastyService } from 'ng2-toasty';
import { Login } from '../../models/login.model';

@Injectable()
export class AuthService {
 // user: User;
  user: Login;
  //loginObj: Login;
  headers: HttpHeaders;
  token: string;

  constructor(
    public http: HttpClient,
    public router: Router,
    // //private toastyService: ToastyService
  ) {
    this.loadStorage();
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  loadStorage() {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.user = JSON.parse(localStorage.getItem('user'));
      console.log('loadStorage: token / user ');
    } else {
      console.log('loadStorage null');
      this.token = '';
      this.user = null;
    }
  }/* 

  saveStorage(id: string, token: string, user: User) {
    localStorage.setItem('id', id);
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    this.user = user;
    this.token = token;
  }

  saveLocalStorage(token: string, user: User) {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    console.log(token);
    this.user = user;
    this.token = token;
  } */

  logout() {
    this.user = null;
    this.token = '';
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.router.navigate(['/login']);
  }

  isAuthenticated() {
    // here you can check if user is authenticated or not through his token
    return this.token.length > 5 ? true : false;
  }
 /*  login(user: User, recordar: boolean = false) {
    if (recordar) {
      localStorage.setItem('username', user.sub);
    } else {
      localStorage.removeItem('username');
    }
    const body = JSON.stringify({ username: user.sub, password: user.password });
    const url = `${BASE_URL}auth/login`;
    return this.http.post<any>(url, body)
      .pipe(map(response => {
        let decode = [];
        decode = response.token.split('.');
        let usu: any;
        let ini;
        let exp;
        usu = JSON.parse(window.atob(decode[1]));
        console.warn('user: ', usu);
        user.idUsuario = usu.idUsuario;
        user.idEmpresa = usu.idEmpresa;
        user.sub = usu.sub;
        // console.log('iat', new Date(usu.iat).toLocaleDateString());
        // console.log('exp', new Date(usu.exp).toLocaleDateString());
        console.log('iat', new Date(usu.iat));
        console.log('exp', new Date(usu.exp));
        // ini = new Date(usu.iat * 1000);
        // exp = new Date(usu.exp * 1000);
        this.saveLocalStorage(response.token, user);
        return true;
      }), catchError(e => {
        this.invalid(
          'Verifique su usuario y contraseña'
        );
        console.error('ERROR', e.error);
        if (e.error.header) {
          Swal.fire(e.error.header, e.error.message, 'error');
        }
        return throwError(e);
      })
      );
  }

  renewToken() {
    return this.http.get(`${BASE_URL}auth/token`).pipe(
      map((response: any) => {
        console.log(response);
        let decode = [];
        decode = response.token.split('.');
        let usu: any;
        let ini;
        let exp;
        usu = JSON.parse(window.atob(decode[1]));
        ini = new Date(usu.iat * 1000);
        exp = new Date(usu.exp * 1000);
        console.log('ini:' + ini);
        console.log('exp:' + exp);
        localStorage.removeItem('token');
        this.saveLocalStorage(response.token, this.user);
        console.error('token renewed:');
        return true;
      }),
      catchError(e => {
        console.error('ERROR', e.error);
        Swal.fire(e.error.header, e.error.message, 'error');
        return throwError(e);
      })
    );
  }

  getToken() {
    return this.token;
  }

  invalid(m) {
    this.toastyService.error({
      title: 'No Autorizado',
      msg: m,
      showClose: false,
      timeout: 3000,
      theme: 'bootstrap'
    })
  } */


  ingresar(loginObject: Login, recordar: boolean = false): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      localStorage.removeItem('token');
      if (recordar) {
        localStorage.setItem('username', loginObject.username);
      } else {
        localStorage.removeItem('username');
      }
      let body = JSON.stringify({
        username: loginObject.username,
        password: loginObject.password,
      });
      this.user = loginObject;
      localStorage.setItem('token', window.btoa(JSON.stringify(body)));
      localStorage.setItem('user', JSON.stringify(this.user));
      this.token = localStorage.getItem('token');
      resolve(true);
    });
  }
}

