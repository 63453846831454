import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthService } from '../services/auth/auth.service';
import { Login } from '../models/login.model';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  username: string;
  recuerdame: boolean = false;

  auth2: any;

  constructor(
    public _authService: AuthService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.username = localStorage.getItem('username') || '';
    if (this.username.length > 1) {
      this.recuerdame = true;
    }
  }

  async ingresar(forma: NgForm) {
    if (forma.invalid) {
      return;
    }
    Swal.fire({
      allowOutsideClick: false,
      type: 'info',
      text: 'Espere por favor...'
    });
    Swal.showLoading();

    let loginObject = new Login(forma.value.username, forma.value.password);
    let correcto = await this._authService.ingresar(loginObject, forma.value.recuerdame);
    if (correcto) {
      this.router.navigate(['/dashboard/analytics']);
    } else {
      this.router.navigate(['/signin']);
    }

    Swal.close();

  }

}
